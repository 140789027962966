.salah-times-container {
  width: 80%;
  max-width: 700px;
  padding: 20px;
  border: 1px solid #3BAF84;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 123, 255, 0.1);
  background-color: white;
}

.salah-times-title {
  text-align: center;  
  margin-bottom: 3rem;
}

.jummah {
  font-weight: bold;
}

.salah-times-table {
  width: 100%;
  border-collapse: collapse;
}

.salah-times-table th, .salah-times-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #dddddd;
}

.salah-times-table th {
  background-color: #3BAF84;
  color: white;
  text-align: center;
}

.date-cell {
  font-weight: bold;
  font-size: larger;
  background-color: #f8f9fa;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; /* Center text within the cell */
  padding: 10px; /* Add some padding to make it look better */
}

.btn-salah {
  background-color: #3BAF84;
  color: white;
  padding: 0.6rem;
  font-family: 'Montserrat', sans-serif;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: large;
  text-align: center;
  text-decoration: none;
  margin: 1.3rem 0 0 0;
  border-width: 0;
}

.loading-message {
  text-align: center;
  font-size: 18px;
  color: #666;
}

.error-message {
  color: red;
  text-align: center;
}

@media (max-width: 768px) {
  .date-cell {
    font-weight: bold;
    font-size: larger;
    background-color: #f8f9fa;
    color: #333;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; /* Center text within the cell */
  }

  .salah-times-table th, .salah-times-table td {
    padding: 5px;
  }

  #salah-times {
    font-size: small;
    padding: 7.5rem 1rem 7.5rem 1rem;
  }

  .salah-times-title {
    margin-bottom: 2rem;
  }

  .btn-salah {
    font-size: small;
  }
}

@media(max-width: 480px) {
  .salah-times-title {
      margin-bottom: 1.5rem;
  }

  .btn-salah {
    font-size: small;
  }
}
