.home-component {
    display: flex;
    /* text-align: left; */
    padding: 10rem 3rem 7.5rem 3rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 3rem;
}

.btn {
    background-color: #3BAF84;
    color: white;
    padding: 1rem;
    font-family: 'Montserrat', sans-serif;
    border-radius: 0.5rem;
    font-weight: bold;
    font-size: large;
    text-align: center;
    max-width: 200px;
    max-height: 60px;
    text-decoration: none;
}    

.btn-container {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    gap: 2.5rem;
}

@media (max-width: 768px) { 
    .bigLogo {
        height: 10rem;
    }

    .btn {
        background-color: #3BAF84;
        color: white;
        padding: 1rem;
        font-family: 'Montserrat', sans-serif;
        border-radius: 0.5rem;
        font-weight: bold;
        font-size:small;
        text-align: center;
        max-width: 150px;
        max-height: 60px;
        text-decoration: none;
    }  

    .home-title {
        font-size: x-large;
    }
}

@media (max-width: 480px) { 
    .bigLogo {
        height: 7rem;
    }

    .btn {
        background-color: #3BAF84;
        color: white;
        padding: 0.5rem;
        font-family: 'Montserrat', sans-serif;
        border-radius: 0.5rem;
        font-weight: bold;
        font-size:small;
        text-align: center;
        max-width: 130px;
        max-height: 30px;
        text-decoration: none;
    }  

    .btn-container {
        gap: 1rem;
    }

    .home-title {
        font-size: large;
    }

    .home-component {
        gap: 2rem;
    }
}