#about-us {
    display: block;
}

.about-title {
    text-align: center;
    margin-bottom: 3rem;
}

.img-container {
    float: left; /* Floats the image to the left */
    margin-right: 20px; /* Adds space between the image and text */
    max-width: 40%; /* Adjusts the size of the image container */
  }
  
  .img-container img {
    width: 100%; /* Ensures the image is responsive */
    height: auto; /* Keeps the image aspect ratio */
    max-height: 20rem;
    display: block; /* Ensures no extra space below the image */
    border-radius: 1rem;
  }
  
  p {
    text-align: left; /* Justifies the text for a cleaner look */
  }

@media(max-width: 768px) {
    .about-title {
        margin-bottom: 2rem;
    }
}

@media(max-width: 480px) {
    .about-title {
        margin-bottom: 1.5rem;
    }
}