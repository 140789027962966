.highlight-row {
  background-color: #3baf8455; /* Light blue background */
  font-weight: bold; /* Make text bold */
}

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 1rem;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    width: 90vw; 
    max-width: 1000px;
    height: auto;
    max-height: 90vh;
    overflow: auto; 
  }
  
  .popup-content {
    max-height: 600px;
    overflow-y: auto;
    overflow: auto;
  }
  
  .close-button {
    background-color: red;
    color: white;
    padding: 0.3rem;
    font-family: 'Montserrat', sans-serif;
    border-radius: 0.5rem;
    font-weight: bold;
    font-size: small;
    text-align: center;
    text-decoration: none;
    margin: 0 0 1rem 0;
    border-width: 0;
    min-width: 5rem;
  }
  
  .monthly-salah-times-table {
    width: 100%;
    border-collapse: collapse;
    display: block;
    overflow-x: auto;
  }
  
  .monthly-salah-times-table th, .monthly-salah-times-table td {
    border: 1px solid #ddd;
    padding: 0.3rem;
    text-align: center;
  }
  
  .monthly-salah-times-table th[colspan="2"] {
    text-align: center;
  }

  @media (max-width: 768px) {
    .popup {
      width: 90vw; 
      max-height: 80vh; 
    }

    .monthly-salah-times-table th, .monthly-salah-times-table td {
      padding: 0.1rem;
    }

    .monthly-salah-times-table {
      display: block;
      overflow-x: auto;
    }
  }
  
  @media(max-width: 480px) {
    .salah-times-title {
        margin-bottom: 1.5rem;
    }
  }
  
  