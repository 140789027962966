.contact-flex {
    display: flex;
    flex-direction: column;
    gap: 2rem; 
    position: relative;
}

.email {
    font-weight: bold;  
}

.info-text {
    font-weight: bold;
}

.contact-info-email-container {
    padding: 1rem 3rem 0 3rem;
}
  
.location-info {
    padding: 0 3rem 0 3rem;
}

.contact-info-icons {
    position: absolute;
    bottom: 0;
    right: 0;
    
}

.youtube {
    padding: 1rem;
}

@media (max-width: 480px) {
    .youtube {
        padding: 0.3rem
    }

    .insta {
        margin: 0 3rem 0 0;
    }
}